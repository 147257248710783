import {
    Button, Col, Input, Layout, message, Row, InputNumber, Typography, Tooltip, Checkbox, Popconfirm, DatePicker
} from 'antd';
import React from 'react';
import moment from "moment";
import NavBar from '../components/header';
import { NewsStoryGenerateAPI, NewsStoryViewAPI, NewsStoryCreateAPI, NewsStoryUpdateAPI, NewsStoryPublishAPI } from "../apis/newsStory";
import { IMSV2ImageSelectionAPI} from "../apis/vcs";
import { TemplateModal } from "../components/templateModal"
import { LanguageDropDown } from "../components/languageDropDown"
import { NewsStoryEditorModal } from "../components/newsStoryEditorModal"
import { CategoryDropDown } from "../components/categoryDropDown"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { IMSImageSearchModal } from "../components/imsImageSearchModal";
import NoAuth from "../components/noAuth";

const { Content, Footer } = Layout;
const { TextArea } = Input;
const { Text } = Typography;


export default class NewsStoryCreate extends React.Component{

    constructor(props) {
        super(props);
        // Don't call this.setState() here!

        var default_expiry_date = new Date();
        default_expiry_date.setDate(default_expiry_date.getDate() + 2);

        this.state = {
            // signin stuff
            signin_name: null,
            signin_email: null,
            id_token_claims: null,
            // id_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiI3YjAzZDAzNi03ZjQ4LTQ5YjAtYWE2MS0yNzhkZDFmMjc5OGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3L3YyLjAiLCJpYXQiOjE2NjMxNTUzMjQsIm5iZiI6MTY2MzE1NTMyNCwiZXhwIjoxNjYzMTU5MjI0LCJhaW8iOiJBVlFBcS84VEFBQUFDWTRwS3ZLQ2ltK2lpc1IxaXRaWHMzbmdJeFduOHZycGVTbVNzekNqNnBrZlVVUG00bldjamIydi9KOVdsZGE0MXhpSUUxRUdwRnlNRWlKdkJ5ODNYeXR6MDJ3d0JCSm5ZSkZtNzJzU01TST0iLCJuYW1lIjoiU2hpdmplZXQgQmhvc2FsZSIsIm5vbmNlIjoiZTg0ODA5NDktYzU3Ny00YzlkLWE1NmItYzkwNmI2ZjNlOGYxIiwib2lkIjoiMjk0YjhhMWMtNDU3MC00M2UwLTlkNmMtZGQ5ZjY0ZDcyYWJjIiwicHJlZmVycmVkX3VzZXJuYW1lIjoic2hpdmplZXQuYmhvc2FsZUBpbm1vYmkuY29tIiwicmgiOiIwLkFRNEE5SncxaVdDZW1VQ0F4SGRhRFA0bnB6YlFBM3RJZjdCSnFtRW5qZEh5ZVlzT0FKMC4iLCJzdWIiOiJsTGR0SFJoY1JOQzU3bmlQZ0E4dkk5VmNybjN1SHQxNGI0YXp2cVBRQWl3IiwidGlkIjoiODkzNTljZjQtOWU2MC00MDk5LTgwYzQtNzc1YTBjZmUyN2E3IiwidXRpIjoibWJCendsUUZja2V3RUFFQTBCd1JBQSIsInZlciI6IjIuMCJ9.IvxN3OgJzjzzn4vtJQHAyN5-C_Pt-YupI0pzpk8AM2hWT2j6JQZ5B5nuBSWrzZni5CZS4CFevxwjeKK-9EhnFLPC9mmAcpv3K6ULdIe9E7WPZGhmW68y1JaKbHUM6MgR9XVafGolH-AmEsWaahthrsvZTJ_kCktonZbzXSvsVxZ5F7Fco3RlK14X6dkw1XPJNjILfsKXVq8pEqDmZ272wumSKATVLJ4s-UDAxofgCBwSu9CgV1Z7KZum7xWTHyjW8Q0hHj93MFo4PEc-5wHZtS9db4bGU7yBfGT2SrkoraV0FT45nYBLOkSzyEtGXeOMETxeThF5U7KhPCNpwz468w",
            id_token: null,
            
            lang_options: [
                "es",
                "pt",
                "bn",
                "kn",
                "ta",
                "te",
                "hi",
                "en",
                "ba",
                "mr",
                "ms",
                "vi",
                "th",
                "fil,"
            ],
            image_cdn_prefix: "https://g.glance-cdn.com/public/dynamic/1440x2560/",
            upload_endpoint: process.env.REACT_APP_UPLOAD_ENDPOINT,

            image_search_modal_open: false,
            ims_selected_image_id: null,
            ims_selected_image_source: null,
            ims_banner_image_url: null,
            image_ids: [],
            image_id_to_source_map: {},

            news_story_editor_modal_open: false,
            news_story_editor_editing_pages: null,

            news_story_publish_api_running: false,
            current_page_index: 0,
            target_language: "en",
            news_story_api_running: false,
            news_story_api_response: null,
            news_story_update_api_running: false,
            news_story_update_response: null,
            source_url: null,
            num_points: 8,
            monetize: true,
            expiry_date: default_expiry_date,
            perspective_zoom: true,
            news_story: null,
            news_story_html: "<p>News Story preview will be shown here</p>",
            news_story_html_url: null,
            news_story_publish_url: process.env.REACT_APP_TRENDS_BASE_URL + "/newz/story",  // TODO: agree this with Engg

            category: "national_v_two",
            category_options: [
                'daily_digest',
                'cricket',
                'sports',
                'entertainment',
                'technology',
                'travel_and_lifestyle',
                'automotive',
                'food_and_health',
                'national_v_two',
                'international',
                'business',
                'fashion',
                'music',
                'fun_facts',
                'games',
                'nature',
                'talent',
                'comedy',
                'tamil_news',
                'telugu_news',
                'kannada_news',
                'marathi_news',
                'bengali_news',
                'home_n_living',
                'celebrity',
                'education',
                'live_game_streams',
                'religion',
                'content_for_apps',
                'ipl',
            ],
           
        };
        
        this.generateNewsStory = this.generateNewsStory.bind(this);
        this.generateNewsStoryRun = this.generateNewsStoryRun.bind(this);
        this.updateNewsStory = this.updateNewsStory.bind(this);
        this.publishNewsStory = this.publishNewsStory.bind(this);
        this.imageSelection = this.imageSelection.bind(this);
        this.toggleImageSearchModal = this.toggleImageSearchModal.bind(this);
        this.toggleNewsStoryEditorModal = this.toggleNewsStoryEditorModal.bind(this);
    }

    onMovePage = (direction, index) => {
        let pages = [...this.state.news_story_editor_editing_pages];

        if (direction === "up" && index > 0) {
            [pages[index - 1], pages[index]] = [pages[index], pages[index - 1]];
        } else if (direction === "down" && index < pages.length - 1) {
            [pages[index], pages[index + 1]] = [pages[index + 1], pages[index]];
        }

        this.setState({ news_story_editor_editing_pages: pages });
    };

    generateNewsStory = () => {
        this.setState({
            news_story_html: "<p>News Story preview will be shown here</p>"
        })
        this.generateNewsStoryRun()    
    }

    generateNewsStoryRun = async () => {
        this.setState({
            news_story_api_running: true,
            news_story_api_response: null,
            news_story_html: "<p>News Story preview will be shown here</p>"
        })
        message.loading("Generating News Story...", 0)
        this.props.refreshIdToken().then((token) => {
            const payload = {
                source_url: this.state.source_url,
                target_language: this.state.target_language,
                stage: "generated",
                num_points: this.state.num_points,
                category: this.state.category,
                monetize: this.state.monetize,
                expiry_date: this.state.expiry_date,
                perspective_zoom: this.state.perspective_zoom,
            }
            console.log("payload: ", payload)
            NewsStoryGenerateAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("News Story API failed!")
                    this.setState({
                        news_story_api_running: false,
                    })
                    console.log("bhai yeh toh fail")
                } else {
                    message.destroy()
                    message.success("News Story generated!")
                    var story = response.message.story;

                    // get the News Story view
                    // TODO: set ads_config to something suitable
                    const payload_view = {
                        story: story,
                        ads_config: null
                    }
                    NewsStoryViewAPI(payload_view, token).then((response2) => {
                        if (response2.status==false) {
                            message.destroy()
                            message.error("News Story view API failed!")
                            this.setState({
                                news_story_api_running: false
                            })
                            console.log("bhai view yeh bhi fail")
                        } else {
                            message.destroy()
                            message.success("News Story view generated!")
                            console.log("bhai view chalgaya")

                            // persist News Story to DB
                            story.html_url = response2.message.news_story_html_url
                            const payload_create = {
                                story: story
                            }
                            NewsStoryCreateAPI(payload_create, token).then((response3) => {
                                if (response3.status==false) {
                                    message.destroy()
                                    message.error("News Story create API failed!")
                                    this.setState({
                                        news_story_api_running: false
                                    })
                                } else {
                                    message.destroy()
                                    message.success("News Story saved!")

                                    // update story state
                                    this.setState({
                                        source_url: story.source_url,
                                        news_story: story,
                                        news_story_html: response2.message.news_story_html,
                                        news_story_html_url: response2.message.news_story_html_url,
                                        news_story_api_response: response,
                                        news_story_api_running: false,
                                    }, () => {
                                        console.log("News Story: ", this.state.news_story)
                                        console.log("bhai yeh toh chal jayega")
                                    })
                                }
                            }).catch((error) => {
                                message.destroy()
                                message.error("Failed to save News Story!")
                                this.setState({
                                    news_story_api_running: false
                                })
                            })
                        }
                    }).catch((error) => {
                        message.destroy()
                        message.error("Failed to generate News Story view!")
                        this.setState({
                            news_story_api_running: false
                        })
                        console.log("bhai failed hai hai")
                    })

                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to generate News Story!")
                this.setState({
                    news_story_api_running: false
                })
            })
            }).catch((error) => {
                message.destroy()
                message.error("Failed to generate News Story!")
                this.setState({
                    news_story_api_running: false
                })
            })
    }

    updateNewsStory = async () => {
        message.loading("Updating News Story view...", 0)
        this.setState({
            news_story_update_api_running: true,
            news_story_api_response: null,
            news_story_html: "<p>News Story preview will be shown here</p>"
        })

        this.props.refreshIdToken().then((token) => {
            const payload_view = {
                story: this.state.news_story,
                ads_config: null
            }
            NewsStoryViewAPI(payload_view, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("News Story view API failed!")
                    this.setState({
                        news_story_update_api_running: false
                    })
                } else {
                    message.destroy()
                    message.success("News Story view updated!")
                    var story = this.state.news_story
                    story.html_url = response.message.news_story_html_url
                    this.setState({
                        news_story_html: response.message.news_story_html,
                        news_story_html_url: response.message.news_story_html_url,
                        news_story: story
                    })

                    const payload_create = {
                        story: this.state.news_story,
                    }
                    NewsStoryUpdateAPI(payload_create, token).then((response2) => {
                        if (response2.status==false) {
                            message.destroy()
                            message.error("News Story database update API failed!")
                            this.setState({
                                news_story_api_running: false,
                            })
                        } else {
                            message.destroy()
                            message.success("News Story updated in database!")
                            this.setState({
                                news_story_update_api_running: false,
                            })        
                        }
                    }).catch((error) => {
                        message.destroy()
                        message.error("Failed to update News Story in database!")
                        this.setState({
                            news_story_update_api_running: false,
                        })
                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to update News Story view!")
                this.setState({
                    news_story_update_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to update News Story!", error)
            this.setState({
                news_story_update_api_running: false
            })
        })
    }

    publishNewsStory = async () => {
        message.loading("Publishing News story...", 0)
        this.setState({
            news_story_publish_api_running: true,
            news_story_publish_response: null,
        })

        this.props.refreshIdToken().then((token) => {
            // publish News story in DB
            NewsStoryPublishAPI(this.state.news_story.id, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("News story publish API failed!")
                    this.setState({
                        news_story_publish_api_running: false,
                        news_story_publish_response: response.message
                    })
                } else {
                    message.destroy()
                    message.success("News story published!")
                    this.setState({
                        news_story_publish_api_running: false,
                        news_story_publish_response: response.message,
                        news_story: response.message.story

                    })
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to publish News story!")
                this.setState({
                    news_story_publish_api_running: false
                })
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to publish News story!", error)
            this.setState({
                news_story_publish_api_running: false
            })
        })
    }


    imageSelection = async (search_string) => {
        // TODO: Add image-selection API call
        // this.setState({
        //     clip_generate_api_running: true
        // })
        if (search_string == "") {
            message.destroy()
            message.info("Enter some text to search an image!")
            return
        }
        this.props.refreshIdToken().then((token) => {
            const payload = {
                params: {
                    "title": search_string,
                    // "description": "",
                    "max_count": 30,
                    "filter_images": false
                    // "duplicate_removal": true,
                    // "blacklisted_removal": true,
                }
            }
            IMSV2ImageSelectionAPI(payload, token).then((response) => {
                if (response.status==false) {
                    message.destroy()
                    message.error("Image Selection API failed!")
                } else {
                    message.destroy()
                    message.success("Fetched Some Fresh Images!")
                }
                if (response.status == true) {
                    console.log("Image Selection API Response: ", response.message)
                    const image_ids = response.message.images.map(item => item.id);
                    const image_id_to_source_map = response.message.images.reduce((acc, item) => {
                        acc[item.id] = item.source;
                        return acc;
                    }, {});
                    this.setState({
                        image_ids: image_ids,
                        selected_image_id: image_ids[0],
                        image_id_to_source_map: image_id_to_source_map,
                    })
                    console.log("Image Ids: ", this.state.image_ids)
                }
            }).catch((error) => {
                message.destroy()
                message.error("Failed to fetch images!")
            })
        }).catch((error) => {
            message.destroy()
            message.error("Failed to fetch images!")
        })
    }

    componentDidMount(){
        // DONT NEED ANYTHING HERE
    }

    toggleImageSearchModal = () => {
        this.setState({
            image_search_modal_open: !this.state.image_search_modal_open
        })
    }

    toggleNewsStoryEditorModal = () => {
        this.setState({
            news_story_editor_modal_open: !this.state.news_story_editor_modal_open
        })
    }

    render() {
        console.log("News Story: ", this.state.news_story)
        const createForm = (
            <div>
                <IMSImageSearchModal
                    isOpen={this.state.image_search_modal_open}
                    onOk={(e) => {
                        var story = this.state.news_story;
                        story.pages[this.state.current_page_index].image_url = this.state.ims_banner_image_url;
                        story.pages[this.state.current_page_index].image_source = this.state.ims_selected_image_source;
                        this.setState({
                            news_story: story
                        })
                        this.toggleImageSearchModal()
                    }}
                    imageSelection={this.imageSelection}
                    default_value={this.state.search_text}
                    image_list={this.state.image_ids}
                    image_cdn_prefix={this.state.image_cdn_prefix}
                    imageOnSelect={(e)=>{
                        this.setState({
                            ims_selected_image_id: e.target.value,
                            ims_selected_image_source: this.state.image_id_to_source_map[e.target.value]?? "",
                            ims_banner_image_url: this.state.image_cdn_prefix + e.target.value + ".jpg",
                        })
                    }}
                    selectedImage={this.state.ims_banner_image_url}
                    signin_name={this.props.username}
                    upload_loading={this.state.image_upload_loading}
                    upload_endpoint={this.state.upload_endpoint}
                    id_token={this.props.idToken}
                    onImUploadChange={(e) => {
                        if (e.file.status == 'done') {
                            var story = this.state.news_story;
                            story.pages[this.state.current_page_index].image_url = e.file.response.image_url;
                            story.pages[this.state.current_page_index].image_source = "";
                            this.setState({
                                news_story: story,
                                image_search_modal_open: false
                            })
                            message.success('Image Uploaded!')
                        }
                    }}
                />

                <NewsStoryEditorModal
                    onMovePage={this.onMovePage}
                    isOpen={this.state.news_story_editor_modal_open}
                    centered
                    onOk={(e) => {
                        var pages = this.state.news_story_editor_editing_pages;
                        var story = this.state.news_story;
                        story.pages = pages;
                        this.setState({
                            news_story: story
                        })
                        this.updateNewsStory()
                        this.toggleNewsStoryEditorModal()
                    }}
                    onCancel={this.toggleNewsStoryEditorModal}
                    onClose={this.toggleNewsStoryEditorModal}
                    onDeletePage={(e) => {
                        var pages = this.state.news_story_editor_editing_pages;
                        pages.splice(e, 1)
                        this.setState({
                            news_story_editor_editing_pages: pages
                        })
                    }}
                    onChange={(field_name, page_index, e) => {
                        var pages = this.state.news_story_editor_editing_pages;
                        pages[page_index][field_name] = e.target.value
                        this.setState({
                            news_story_editor_editing_pages: pages
                        })
                    }}
                    onReplaceImage={(page_index) => {
                        var pages = this.state.news_story_editor_editing_pages;
                        this.setState({
                            image_ids: pages[page_index].image_ids,
                            ims_banner_image_url: pages[page_index].image_url,
                            current_page_index: page_index
                        })
                    }}
                    toggleImageSearchModal={this.toggleImageSearchModal}
                    editing_pages={this.state.news_story_editor_editing_pages}
                    image_cdn_prefix={this.state.image_cdn_prefix}
                />
                
                <Layout>
                    <Content>
                        <br/>
                        <br/>
                        <h3 style={{borderBottom: '1px solid #ddd'}}>Generate a Self-Hosted News Story</h3>
                        <Row style={{ width: '100%' }}>
                            <Col span={12}>
                                <Text>
                                    Prompt
                                </Text>
                                <Tooltip trigger={['focus']} title={"Submit a URL or an Article."} placement="topLeft">
                                    <TextArea
                                        style={{
                                            fontSize: '13px',
                                            minHeight: '55px'
                                        }}
                                        placeholder="Submit a link to an article or just the article text :)"
                                        onChange={(e) => {
                                            this.setState({
                                                source_url: e.target.value
                                            })
                                        }}
                                    />

                                </Tooltip>
                            </Col>
                            <Col span={3} style={{ paddingLeft: '20px' }}>
                                <Text
                                    style={{
                                        paddingLeft: '2px'
                                    }}
                                >
                                    Pages#
                                </Text>
                                <br />
                                <Tooltip trigger={['focus']} title={"How many pages do you want?"} placement="topLeft">
                                    <InputNumber
                                        style={{
                                            width: '70px',
                                            paddingLeft: '2px'
                                        }}
                                        value={this.state.num_points}
                                        min={this.state.monetize ? 8 : 5} max={12} defaultValue={8} onChange={(e) => {
                                            this.setState({
                                                num_points: e
                                            })
                                        }} />
                                </Tooltip>
                                <br />
                                <br />
                                <Text
                                     style={{
                                        paddingLeft: '2px'
                                    }}
                                >
                                    Article Category
                                </Text>
                                <br />
                                <CategoryDropDown
                                    style={{
                                        width: '100%',
                                    }}
                                    category={this.state.category}
                                    category_options={this.state.category_options}
                                    onCategorySelect={(e) => {
                                        console.log("onCategorySelect - " + e)
                                        this.setState({
                                            category: e
                                        })
                                    }}
                                />

                            </Col>
                            <Col span={4} style={{ paddingLeft: '20px' }}>
                                <Text
                                    style={{
                                        paddingLeft: '2px'
                                    }}
                                >
                                    Language
                                </Text>
                                <br />
                                <LanguageDropDown
                                    language={this.state.target_language}
                                    language_options={this.state.lang_options}
                                    onLanguageSelect={(e) => {
                                        console.log("onLanguageSelect - " + e)
                                        this.setState({
                                            target_language: e
                                        })
                                    }}
                                />
                                <br />
                                <br />
                                <Text
                                    style={{
                                        paddingLeft: '2px'
                                    }}
                                >
                                    Article Expiry Date
                                </Text>
                                <br />
                                <DatePicker
                                    style={{
                                        width: '100%',
                                    }}
                                    defaultValue={moment(this.state.expiry_date)}
                                    onChange={(e) => {
                                        this.setState({
                                            expiry_date: e
                                        })
                                    }}
                                />
                            </Col>
                            <Col span={1}>
                            </Col>
                            <Col span={4}>
                                <Checkbox
                                    checked={this.state.monetize}
                                    style={{
                                        paddingTop: '32px',
                                        paddingLeft: '2px'
                                    }}
                                    onChange={(e)=>{
                                            var num_points = this.state.num_points
                                            if (num_points < 8) {
                                                num_points = 8
                                            }
                                            this.setState({
                                                monetize: e.target.checked,
                                                num_points: num_points
                                            })
                                        }}
                                >
                                    Monetize
                                </Checkbox>
                                <br />
                                <Checkbox
                                    checked={this.state.perspective_zoom}
                                    style={{
                                        paddingTop: '32px',
                                        paddingLeft: '2px'
                                    }}
                                    onChange={(e)=>{
                                        this.setState({
                                            perspective_zoom: e.target.checked
                                        })
                                    }}
                                >
                                    Perspective Zoom
                                </Checkbox>
                            </Col>
                        </Row>
                        <Row style={{
                            marginTop: '10px'
                        }}>
                            <Col span={8}>
                                <Button type="primary" block
                                style={{
                                    marginTop: '15px'
                                }}
                                    onClick={this.generateNewsStory} 
                                    loading={this.state.news_story_api_running}
                                    disabled={this.state.news_story && this.state.news_story.stage == "published"}
                                >
                                    Generate News Story 
                                </Button>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <h3 style={{borderBottom: '1px solid #ddd'}}> Output </h3>
                        <Row>
                            <Col span={9}>
                                <Text style={{ fontWeight: 'bold' }}>
                                    News Story Preview
                                </Text>
                                <br />
                                <iframe
                                    style={{
                                        width: '350px',
                                        height: '700px'
                                    }}
                                    title="view"
                                    src={"data:text/html,"+encodeURIComponent(this.state.news_story_html)}/>
                            </Col>
                            <Col span={7}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        var pages = this.state.news_story.pages;
                                        this.setState({
                                            news_story_editor_editing_pages: pages
                                        }, () => {
                                            this.toggleNewsStoryEditorModal()
                                        })
                                    }}
                                    style={{
                                        width: '100%',
                                        overflow: 'clip',
                                        marginTop: '20px',
                                        display: 'block'
                                    }}
                                    block 
                                    loading={this.state.news_story_update_api_running}
                                    disabled={
                                        this.state.news_story === null
                                        || (this.state.news_story && this.state.news_story.stage == "published")
                                        || this.state.news_story_api_running
                                        || this.state.news_story_publish_api_running
                                    }
                                >
                                    Edit and Update News Story
                                </Button>
                                <br/>
                                <br/>
                                <Popconfirm
                                    title="Do you really want to publish this news story? Editing will not be possible after publishing."
                                    onConfirm={(e) => {
                                        this.publishNewsStory()
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button
                                        type="primary"
                                        style={{
                                            width: '100%',
                                            marginTop: '20px',
                                            display: 'block'
                                        }}
                                        loading={this.state.news_story_publish_api_running}
                                        disabled={
                                            this.state.news_story === null
                                            || (this.state.news_story && this.state.news_story.stage == "published")
                                            || this.state.news_story_api_running
                                            || this.state.news_story_update_api_running
                                        }
                                    >
                                        Publish News Story
                                    </Button>
                                </Popconfirm>
                            </Col>
                            <Col span={5} style={{ paddingLeft: "20px"}}>
                                <Row>
                                    <Button
                                        style={{
                                            marginTop: '20px',
                                            display: 'block'
                                        }}
                                        onClick={() => navigator.clipboard.writeText(this.state.news_story_html_url)}
                                        disabled={
                                            this.state.news_story === null
                                            || this.state.news_story_api_running
                                            || this.state.news_story_update_api_running
                                        }
                                    >
                                        COPY NEWS STORY PREVIEW URL
                                    </Button>
                                </Row>
                                {/* {this.state.news_story && this.state.news_story.stage == "published" ? (
                                    <Row>
                                        <Button 
                                            style={{
                                                marginTop: '22px',
                                                display: 'block'
                                            }}
                                            onClick={() => navigator.clipboard.writeText(this.state.news_story_publish_url + "/" + this.state.news_story.id)}
                                            disabled={
                                                this.state.news_story === null
                                                || this.state.news_story_api_running
                                                || this.state.news_story_update_api_running
                                                || this.state.news_story_publish_api_running
                                            }
                                        >
                                            COPY NEWS STORY PUBLISHED URL
                                        </Button>
                                    </Row>
                                ) : (
                                    <></>
                                )} */}
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </div>
        )

        return (
            <>
                <AuthenticatedTemplate>
                    <Layout style={{'min-height':'100vh'}}>
                        <NavBar
                            authHandler={async (instance) => {await this.authHandler(instance)}}
                            authRefresh={this.authRefresh}
                            signin_name={this.props.username}
                        />
                        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: 560 }}>
                                {createForm}
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                        <img
                            src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"
                            alt="logo"
                            style={{
                                height:'20px',
                                display:'inline',
                                paddingRight:'10px'
                            }}/>
                            ©2023 Created by TAG AI Team
                        </Footer>
                    </Layout>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuth />
                </UnauthenticatedTemplate>
            </>
        )
    }
}
